<template>
  <div>
    <div class="top">
      <b-container class="selectRD">
        <b-row class="topPad">
          <!-- <b-col>
            <b-button
              :to="{
                name: 'schedule',
                query: {
                  id: id,
                  code: code
                }
              }"
              class="backBtn"
              >BACK TO SCHEDULE</b-button
            >
          </b-col> -->
        </b-row>
        <b-row cols="yellowOuterBlock">
          <b-col cols="12" class="yellowBlock"></b-col>
        </b-row>
        <b-row class="tourCard">
          <b-col
            lg="4"
            style="padding:0;background-repeat: no-repeat; background-size: cover;"
            class="courseimage-hide reportImage"
            :style="{
              backgroundImage:
                'url(' +
                DEFIMAGESAMS3 +
                'sst' +
                '/' +
                'courses' +
                '/' +
                id +
                '/' +
                tourinfo.course_code +
                '/' +
                tourinfo.course_image_file_1 +
                ')'
            }"
          >
            <div class="over"></div>
          </b-col>
          <b-col lg="8" style="padding: 25px;">
            <b-row>
              <b-col lg="7" class="dateandtime">
                <h3 class="tourDate">
                  {{ tourinfo.dates }}
                </h3>
                <h3 class="tourCourse">
                  <span class="FullName">{{ tourinfo.full_name }}</span>
                  <br />
                  <span class="tourCourseTwo">{{ tourinfo.course_name }}</span>
                </h3>
              </b-col>
            </b-row>
            <hr />
            <b-row class="bottomsection-info">
              <b-col class="infoPad" cols="12" lg="4" md="4" sm="12">
                <p class="otherValue">
                  {{ tourinfo.rounds }}
                </p>
                <p class="otherInfo">
                  Number of Rounds
                </p>
              </b-col>
              <b-col class="infoPad" cols="12" lg="4" md="4" sm="12">
                <p
                  class="otherValue"
                  v-if="tourinfo.total_prize_fund.length > 0"
                >
                  {{ tourinfo.total_prize_fund }}
                </p>
                <p
                  class="otherInfo"
                  v-if="tourinfo.total_prize_fund.length > 0"
                >
                  Prize Fund
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="infoPad"
                cols="12"
                lg="7"
                md="7"
                sm="12"
                order="2"
                order-sm="2"
                order-md="1"
                order-lg="1"
              >
                <b-button
                  :to="{
                    name: 'tournament',
                    query: {
                      id: id,
                      code: tourinfo.code
                    }
                  }"
                  class="tourBtn"
                  >TOURNAMENT INFORMATION</b-button
                >
              </b-col>
              <b-col
                cols="12"
                lg="5"
                md="5"
                sm="12"
                order="1"
                order-sm="1"
                order-md="1"
                order-lg="2"
              >
                <b-img
                  class="courseLogo sponLogo"
                  :src="tourinfo.sponsor_logo"
                ></b-img>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-row class="selectReport">
        <span class="reportsPad">
          <b-col lg="12" md="12" cols="12" class="dropSel">
            <b-row class="reportDropdown">
              <b-col>
                <!-- <h3 class="selectReport">
                  SELECT REPORT
                </h3> -->
                <div :class="drop">
                  <b-nav card-header pills align="center">
                    <b-dropdown
                      v-if="Array.isArray(tourinfo.reports.reports_entry)"
                      :text="title"
                      :config="config"
                    >
                      <b-dropdown-item
                        v-on:click="changeReport"
                        v-for="(reports, index) in tourinfo.reports
                          .reports_entry"
                        :key="index + Math.random()"
                        :title="reports.report_url"
                        :value="reports.report_title"
                        v-show="
                          reports.report_title !== 'Course Statistics' &&
                            reports.report_title !== 'Scoring Statistics' &&
                            reports.report_title !== 'Live Scoring Monitor'
                        "
                        >{{ reports.report_title }}</b-dropdown-item
                      >
                    </b-dropdown>
                    <b-dropdown v-else :text="title">
                      <b-dropdown-item
                        v-on:click="changeReport"
                        v-for="(reports, index) in tourinfo.reports"
                        :key="index + Math.random()"
                        :title="reports.report_url"
                        :value="reports.report_title"
                        >{{ reports.report_title }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-nav>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="tmparams.multi_course === 'Y'"
                class="col-md-6 multi"
              >
                <span
                  class="multiList"
                  v-for="(multi, index) in tmparams.courses.courses_entry"
                  :key="index"
                >
                  <span :class="multi.course_X_colour" class="ballpin">•</span
                  ><span class="multiName">{{ multi.course_X_id }}</span>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </span>
      </b-row>
    </b-container>
    <div class="mid">
      <div>
        <div class="reports container">
          <div
            v-if="
              this.currentReport.indexOf('tmentry') !== -1 ||
                this.currentReport.indexOf('tmdraw') !== -1 ||
                this.currentReport.indexOf('tmresult') !== -1 ||
                this.currentReport.indexOf('tmoomt') !== -1 ||
                (this.currentReport.indexOf('tmscore') !== -1 &&
                  data.match_play === 'Y')
            "
          >
            <ReportTable
              :data="data"
              :previousData="previousData"
              :home="home"
              :title="title"
              :season="tmparams.tm_params.season_code"
              :code="tmparams.code"
              :config="config"
              :match="tmparams.match_play"
              :team="teamEvent"
              :showGender="showGender"
              :is_qualifier="is_qualifier"
              :stableford="stableford"
              :show_info_web="show_info_web"
              :hide_score_card="hide_score_card"
            />
          </div>
          <div v-else-if="this.currentReport.indexOf('tmscore') !== -1">
            <div class="TitleArea">
              <span v-if="title.includes('Score')">
                <h3 class="courseDates TitleAreaScore">
                  {{ data.course_dates }}
                </h3>
                <h3 class="courseDates lastRealTime-update">
                  Last Real Time Update
                  {{ data.last_scores_update_date }} -
                  {{ data.course_city }} Time -
                  {{ data.last_scores_update_time }}
                  {{ data.last_scores_update_date }}
                </h3>
              </span>
              <template v-if="showGender == 'Y'">
                <p class="showPgender">
                  <span class="multiDot-size-reportgender male">• </span> = Men
                  Professional <br />
                  <span class="multiDot-size-reportgender female">• </span> =
                  Ladies Professional
                </p>
              </template>
            </div>
            <b-tabs class="tabsLi">
              <template v-if="data.hide_gross_sb == 'Y'">
                <b-tab title="LEADERBOARD" active>
                  <template v-if="data.stableford == 'Y'">
                    <ScoreTeam
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                  <template v-else>
                    <ReportTable
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                </b-tab>
                <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
                  <HBH
                    :data="data"
                    :previousData="previousData"
                    :config="config"
                    :title="title"
                    :showGender="showGender"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
              </template>
              <template v-else>
                <b-tab title="LEADERBOARD" active>
                  <ReportTable
                    :data="data"
                    :title="title"
                    :home="false"
                    :config="config"
                    team="N"
                    :match="tourinfo.match_play"
                    :code="code"
                    :season="season"
                    :showGender="showGender"
                    :is_qualifier="is_qualifier"
                    :stableford="stableford"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
                <b-tab title="TEAM" v-if="teamEvent === 'Y'">
                  <template v-if="data.stableford == 'Y'">
                    <ScoreTeam
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                  <template v-else>
                    <ReportTable
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                </b-tab>
                <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
                  <HBH
                    :data="data"
                    :previousData="previousData"
                    :config="config"
                    :title="title"
                    :showGender="showGender"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
              </template>
            </b-tabs>
          </div>
          <div v-else-if="this.currentReport.indexOf('tmlsmon') !== -1">
            <b-tabs class="tabsLi">
              <template v-if="data.hide_gross_sb == 'Y'">
                <b-tab title="Leaderboard" active>
                  <template v-if="data.stableford == 'Y'">
                    <ScoreTeam
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :scoreFound="scoreFound"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                  <template v-else>
                    <ReportTable
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :scoreFound="scoreFound"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                </b-tab>
                <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
                  <HBH
                    :data="data"
                    :previousData="previousData"
                    :config="config"
                    :title="title"
                    :showGender="showGender"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
              </template>
              <template v-else>
                <b-tab title="Leaderboard" active>
                  <ReportTable
                    :data="data"
                    :title="title"
                    :home="false"
                    :config="config"
                    team="N"
                    :match="tourinfo.match_play"
                    :code="code"
                    :season="season"
                    :scoreFound="scoreFound"
                    :showGender="showGender"
                    :is_qualifier="is_qualifier"
                    :stableford="stableford"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
                <b-tab title="TEAM" v-if="teamEvent === 'Y'">
                  <template v-if="data.stableford == 'Y'">
                    <ScoreTeam
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :scoreFound="scoreFound"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                  <template v-else>
                    <ReportTable
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :scoreFound="scoreFound"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                </b-tab>
                <b-tab title="Hole by Hole" title-item-class="nonactive HBH">
                  <HBH
                    :tabs="tabs"
                    :data="data"
                    :scores="data.scores.scores_entry"
                    :previousData="previousData"
                    :config="config"
                    :title="title"
                    :showGender="showGender"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
              </template>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReportTable from "@/components/reportTable.vue";
import { HBH, ScoreTeam } from "@ocs-software/vue-ocs";

export default {
  name: "Reports",
  props: ["config", "apiClass"],
  components: {
    ReportTable,
    HBH,
    ScoreTeam
    // HBHTEST
  },
  data() {
    return {
      tourinfo: [],
      data: [],
      previousData: [],
      tmparams: [],
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title,
      polling: null,
      id: this.$route.query.id,
      code: this.$route.query.code,
      season: ""
    };
  },
  metaInfo() {
    return {
      title: "Reports",
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description }
      ]
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        axios
          .get(this.reportTitle)
          .then(response => (this.data = response.data))
      );
    },
    updateStuff: function() {
      // console.log("GET DATA!" + new Date().getTime());
      axios
        .get(this.reportTitle + "&rand=" + new Date().getTime())
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_APIFULL_URL +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.tmparams = response.data;
          this.tourinfo = response.data;
          // console.log("this.tmparams -- line 245")
          // console.log(this.tmparams)
          // this.pollData();
        });
    }
    // pollData() {
    //   this.polling = setInterval(() => {
    //     // console.log("Run refresh two");
    //     this.updateStuff();
    //     // this.setTimeout();
    //   }, 120000);
    // }
  },
  computed: {
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        reports_entry =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result")
        // !reports_entry.report_title.indexOf("Live Scoring Monitor")
      );
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_API +
          "sst" +
          "/cache/" +
          this.apiClass +
          "/" +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              this.config.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          // console.log("resNum === ");
          // console.log(resNum1);
          url =
            this.config.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    }
  },
  created() {
    // setInterval(this.updateStuff, 120000);
    this.polling = setInterval(() => {
      // console.log("Run refresh two");
      this.updateStuff();
      // this.setTimeout();
    }, 30000);
    // console.log("update");
  },
  mounted() {
    setTimeout(() => {
      console.log("RUN TIMEOUT");
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_APIFULL_URL +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.tmparams = response.data;
          this.showGender = response.data.show_gender;
          this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3;
          this.tourinfo = response.data;
          this.is_qualifier = response.data.is_qualifier;
          this.stableford = response.data.stableford;
          this.show_info_web = response.data.tm_params.show_info_web;
          this.hide_score_card = response.data.hide_score_card;
        });
    }, 1000);
  }
};
</script>

<style scoped>
.showPgender {
  color: #000;
  font-weight: 600;
}
span.multiDot-size-reportgender {
  font-size: 30px !important;
  line-height: 0px !important;
  position: relative;
  top: 6px;
}
.female {
  color: #e33;
}
.male {
  color: #037;
}
.courseimage-hide.reportImage.col-lg-4 {
  background-repeat: no-repeat;
  background-position: center;
}
img.courseLogo {
  max-height: 120px;
  width: auto;
  margin-top: -30px;
  float: right;
}
::v-deep .dropdown-menu.show {
  display: block;
  width: 100%;
  text-align: center;
}
.courseImg {
  width: 100%;
  /* min-height: 352px; */
  min-height: 360px;
  border-radius: 0px 0px 0px 0px;
  min-width: 1050px;
}
.row.row-cols-yellowouterblock {
  height: 20px;
  background-color: #000;
  border-radius: 10px 10px 0px 0px;
}
.tourDate {
  color: #000;
  font-size: 13px;
  margin-bottom: 30px;
  margin-top: 25px;
  text-transform: capitalize;
}
.tourCourse {
  color: #6c6c6c;
  font-weight: 700;
  font-size: 21px;
}
.FullName {
}
.tourCourseTwo {
  color: #939393;
  font-size: 9pt;
  font-weight: 100;
  margin-top: 25px;
  display: block;
  font-weight: bold;
}
.otherValue {
  color: #000;
  font-weight: 700;
  margin-bottom: 0;
  text-align: left;
}
.otherInfo {
  color: #939393;
  margin-bottom: 0;
  text-align: left;
  font-size: 9pt;
}
.tourBtn {
  display: block;
  /* margin: auto; */
  background: #000;
  border: none;
  color: #fff !important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 11px;
  margin-top: 30px;
  height: 35px;
  line-height: 26px !important;
}
.tourBtn:hover {
  display: block;
  /* margin: auto; */
  background: #2c2c2c;
  border: none;
  color: #fff !important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  /* font-weight: 300; */
  margin-top: 30px;
  height: 35px;
  line-height: 26px !important;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 0;
}
.over {
  overflow: hidden;
  height: 100%;
  border-radius: 0px 0px 0px 0px;
}
::v-deep .tabsLi > div > ul > li {
  margin-right: 20px;
}
.ballpin {
  position: relative;
  font-size: 65px !important;
  line-height: 0px !important;
  vertical-align: middle;
  top: -4px;
}
span.multiName {
  color: #016837;
  font-size: 15pt;
  font-weight: 700;
}
.col-md-6.multi.col {
  text-align: center;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 38px;
}
.reports {
  background: white;
  /* padding: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}
::v-deep .dropdownReport {
  margin: auto;
  text-align: center;
}
.selectReport {
  margin: auto;
  text-align: center;
  display: block;
}
.toptournInfo {
  margin-left: -2px;
  margin-right: -2px;
}
span.multiText {
  color: #016837;
  font-size: 15pt;
  font-weight: 700;
}
span.multiDot-size {
  position: relative;
  font-size: 65px !important;
  line-height: 0px !important;
  vertical-align: middle;
  top: -4px;
}
.multiCourseIndex {
  text-align: center;
  margin-top: 3em;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1e90ff;
}
.R {
  color: #bb0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}

.B > th {
  background-color: #333333 !important;
}
.Y > th {
  background-color: #d8db22 !important;
}
.C > th {
  background-color: #1e90ff !important;
}
.R > th {
  background-color: #bb0000 !important;
}
.G > th {
  background-color: #3c9933 !important;
}
.O > th {
  background-color: #e07020 !important;
}
.lastRealTime-update {
  margin-top: 30px;
  margin-bottom: 2em;
}
::v-deep .tabsLi > div > ul > li {
  margin-right: 20px;
}
::v-deep .nav-tabs {
  border-bottom: 0;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  /* background-color: #000; */
  background-color: #000;
  /* border-color: #dee2e6 #dee2e6 #fff; */
  color: #fff !important;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0px;
}
::v-deep .nav-tabs .nav-link {
  padding-left: 30px;
  padding-right: 30px;
  /* background-color: #000; */
  /* background-color: #000; */
  border-radius: 0px;
  color: #000 !important;
  border: 0;
  border-top-left-radius: 0.25rem!important;
  border-top-right-radius: 0.25rem!important;
  font-size: 10pt;
}
.TitleArea {
  margin-bottom: 0px;
  margin-top: 5px;
  padding-left: 15px;
  display: none;
}
.TitleAreaScore {
  margin-bottom: 25px;
  margin-top: 40px;
}
h3.courseDates {
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: left !important;
}
::v-deep a.dropdown-item {
  text-transform: capitalize;
  /* font-size: 14px; */
  padding: 0rem 1.5rem;
  border-radius: 0px;
  text-align: left;
  color: #000 !important;
  font-size: 1rem;
}
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}
.splash {
  width: 100%;
}
.row.tourCard {
  background: #fff;
  border-radius: 0px 0px 0px 0px;
}
.top {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
}
.topPad {
  padding-top: 3.5rem;
}
.backBtn {
  background: rgb(242, 175, 20);
  padding: 10px;
  color: #fff !important;
  border: 0;
  font-size: 12pt;
  margin-bottom: 5em;
  border-radius: 0px;
}
.backBtn:hover {
  background: #000;
  padding: 10px;
  color: #fff !important;
  border: 0;
  font-size: 12pt;
  margin-bottom: 5em;
  border-radius: 0px;
}
.reportInfo {
  background-color: #ffffff;
  border-radius: 10px 0px 0px 10px;
}
.tourInfo {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 2em;
}
.reportInfo {
  padding: 25px;
}
.dateTitle {
  padding-left: 35px;
}
.reportDate {
  color: #000;
  font-weight: bold;
  font-size: 20pt;
  margin-bottom: 50px;
}
.reportTitle {
  color: #026034;
  font-weight: bold;
  font-size: 20pt;
}
.courseName {
  color: #939393;
  font-size: 12pt;
  font-weight: 100;
  margin-top: 0px;
}
.logoOverflow {
  overflow: hidden;
  height: 210px;
}
.sponLogo {
  max-height: 120px;
  width: 100px;
  margin-top: 25px;
  float: right;
}
.secondInfo {
  margin-top: 30px;
}
.listTourInfo {
  color: #939393;
  margin-bottom: 0;
  text-align: center;
  font-size: 10pt;
}
.valueReport {
  color: #026034;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}
.reportTourBtn {
  display: block;
  margin: auto;
  background: #000;
  border: none;
  color: #fff !important;
  border-radius: 0px;
  padding: 5px 20px 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
}
.memberButton {
  display: block;
  margin: auto;
  border: none;
  color: #fff !important;
  border-radius: 0px;
  padding: 5px 20px 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
}
.backgroundTourImg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0px 10px 10px 0px;
}
.secondList {
  color: #939393;
  margin-bottom: 0;
  text-align: left;
  font-size: 10pt;
}
.secondLead {
  font-size: 1.25rem;
  font-weight: 300;
  text-align: left;
  color: #000;
  margin-bottom: 0;
}
.tourInfoHeading {
  color: #000;
  margin-top: 0px;
  font-size: 24px;
  padding-bottom: 0.3em;
  line-height: 1.25;
}
tr > th {
  padding: 0.55rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
  background: #000;
  color: #fff;
  font-size: 1rem;
}
tr > td {
  padding: 0.55rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 1rem;
}
.reportSele {
  padding: 20px;
  margin-top: 2em;
}
.selectReport {
  text-align: center;
  color: #026034;
  text-transform: capitalize;
  margin-top: 60px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 25px;
}
::v-deep .dropdown > button {
  text-align: left;
  display: block;
  margin: auto;
  border-radius: 0;
  width: 475px;
  background-color: white;
  color: #495057;
  border: 1px solid #e0e0e0;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13px;
}
.greenProfile {
  background-color: #016837;
}
::v-deep .modal-content {
  background-color: #f0f0f0;
}
::v-deep .modal-title {
  color: #026034;
}
::v-deep .modal-header {
  border-bottom: 0;
}
@media only screen and (max-width: 990px) {
  .reportInfo {
    background-color: #ffffff;
    border-radius: 10px;
  }
  .top {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  ::v-deep li.nav-item.nonactive.HBH {
    display: none !important;
  }
  ::v-deep .tabsLi > div > ul > li {
    margin-right: 0px;
    display: contents !important;
  }
  .dateTitle {
    padding-left: 0px;
  }
  ::v-deep .dropdown > button {
    text-align: left;
    display: block;
    margin: auto;
    border-radius: 0;
    width: 300px !important;
    background-color: white;
    color: #495057;
    border: 1px solid #e0e0e0;
    text-transform: capitalize;
    font-weight: 400;
  }
  ::v-deep .dropdown-toggle::after {
    display: inline-block;
    margin-left: 1.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    margin-top: 10px;
  }
}
</style>
